.react-pdf__Page__canvas {
  width: 100% !important;
  height: 500px !important;
  border: 2px solid #38879f !important;
  border-radius: 25px !important;
}

::-webkit-scrollbar {
  display: none;
}
@media screen and (min-width: 768px) and (max-width: 1200px){

  .react-pdf__Page__canvas {
      height: 350px !important;
  }
}