html, body, .App{
  font-family: "Lato", sans-serif !important;
}
.App {
  text-align: center;
}
 .custom-stepper-div .MuiMobileStepper-root .MuiMobileStepper-progress{
    margin: auto;
    width: 820px;
  }
 .ulpoad-label{
     width: 140px !important;
    margin: auto;
 }
 .custom-chip span {
  width: 100%;
  padding: 0 5px;
}
.custom-chip span div {
  width: 100%;
}
@media screen and (min-width: 320px) and (max-width: 767px){
  .app-logo-role{
    width: 150px;
    height: 150px;
  }
      .custom-chip .MuiChip-label {
    width: 100%;
  }
  .back-icon{
    top: 4vh !important;
    width: 30px;
        height: 30px;
  }
  .back-icon-condition{
      width: 25px;
        height: 25px;
  }
  .custom-stepper-div .MuiMobileStepper-root .MuiMobileStepper-progress{
    margin: auto;
    width: 100%;
  }
  .custom-otp-input input{
        width: 40px !important;
        height: 40px !important;
        margin: 0 4px !important;
        font-size: 13px !important;
        border-radius: 8px !important;
  }
  .flie-upload-img{
      width: 100px !important;
      height: 100px !important;
  }
  .camera-imgg{
        width: 35px !important;
        height: 35px !important;
        right: -15px !important;
        bottom: 10px !important;
  }
  .ulpoad-label {
    width: 100px !important;
}
  .mobile-iPad-d-none{
      display: none;
  }
  .w-100-input div{
      width: 100% !important;
  }
  .w-100-mobile{
      width: 100%;
  }
  .profile-img{
      height: 45px !important;
      width: 45px !important;
  }
    .profile-img-inner{
      height: 100px !important;
      width: 100px !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 1200px){
    .custom-stepper-div .MuiMobileStepper-root .MuiMobileStepper-progress{
    margin: auto;
    width: 100%;
  }
    .custom-chip .MuiChip-label {
    width: 100%;
  }
    .back-icon{
    top: 4vh !important;
    width: 30px;
        height: 30px;
  }
     .w-100-input div{
      width: 100% !important;
  }
     .area-desc {
    width: 100% !important;
}
     .profile-img{
      height: 45px !important;
      width: 45px !important;
  }
     .profile-img-inner{
      height: 100px !important;
      width: 100px !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 1200px) and (orientation: landscape){
     .custom-stepper-div .MuiMobileStepper-root .MuiMobileStepper-progress{
    margin: auto;
    width: 100%;
  }
}